.arrow-group {
    display: flex;
    flex-direction: column;
    padding: 3px 20px 3px 3px;
}

.move-arrow {
    cursor: pointer;
    border-radius: 2px;
    padding: 2px 5px 2px 5px;
    transition-duration: 0.2s;
}

.move-arrow:active {
    background-color: rgba(133, 187, 101, 0.5);
}