.toolbar {
  height: 50px;
  background-color: #85bb65;
  justify-content: center;
}

.window {
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Overriding Bootstrap styles */
.btn {
  background-color: #85bb65 !important;
  border-color: #85bb65 !important;
}

.btn-primary {
  background-color: var(--bs-primary) !important;
}

.btn-text:active {
  background-color: var(--bs-green) !important;
}

.btn-outline-primary {
  background-color: #fff !important;
  border-color: var(--bs-btn-color) !important;
}

.btn-outline-primary:hover {
  background-color: var(--bs-btn-color) !important;
}

.btn-text:focus {
  outline: none;
  --bs-btn-border-color: none;
}

.form-check-input:checked {
  background-color: #85bb65 !important;
  border-color: #85bb65 !important;
}
