table {
    border-radius: 10px;
}

thead tr th {
    background-color: rgba(133, 187, 101, 0.75) !important;
}

thead th:hover {
    background-color: rgba(133, 187, 101, 1) !important;
    cursor: pointer;
}

tbody tr:hover {
    background-color: rgba(13, 110, 253, 0.3) !important;
    cursor: pointer;
}

.table-cell-highlight {
    background-color: rgb(255, 255, 0) !important;
}